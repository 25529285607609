.about-container {
    display: flex;
}

@media (max-width:1000px) {
    .about-container {
        flex-direction: column;
    }
    .about-text {
        width: 80% !important;
        margin: auto !important;
        margin-top: 50px !important;
    }
    .about-img {
        margin:auto;
        margin-top:50px !important;
    }
}

.about-text {
    height:fit-content;
    font-size: 24px;
    width: 50%;
    position: relative;
    margin: 60px;
    text-align: justify;
}

.about-text::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    background-color: var(--primary-color);
    height:3px;
    width: 250px;
}

.about-img {
    width: 275px;
    height: 400px;
    background: url('/public/assets/images/20230324_172450.jpg') 485px/cover;
    border-radius: 50%;
}