.contact .heading {
    width:max-content;
    margin:150px auto auto auto;
    width: 100%;
    text-align: center;
}

.contact-box {
    border: 3px solid var(--primary-color);
    border-radius:30px;
    padding: 15px;
    width: 400px;
    margin:100px auto auto auto;
}

.input input,textarea {
    outline: none;
    padding: 15px;
    color: var(--primary-color);
    background:black;
    border:2px solid var(--primary-color);
    border-radius: 25px;
    width: 330px;
    font-family: 'Montserrat';
}

textarea {
    height: 100px;
    resize:vertical;
}

input::placeholder, textarea::placeholder {
    color: var(--primary-color);
    opacity: 50%;
    font-family: 'Montserrat';
}

.msg.input {
    position: relative;
}

.input {
    margin: 20px;
}

.submit-button {
    position: absolute;
    bottom:20px;
    right: 20px;
}

.submit-button button {
    position: relative;
    border: 2px solid var(--primary-color);
    outline: none;
    border-radius: 10px;
    background-color: black;
    color: var(--primary-color);
    font-family: 'Montserrat';
    width: 80px;
    height:30px;
}

.submit-button button:active {
    filter: brightness(150%) !important;
}

.submit-button button:hover {
    filter: brightness(120%);
    cursor: pointer;
}

.submit-button button::after {
    content: '';
    background: url('/public/assets/icons/send.png') center/cover;
    filter: brightness(0) saturate(100%) invert(91%) sepia(23%) saturate(510%) hue-rotate(353deg) brightness(89%) contrast(89%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 20px;
    height: 20px;
}

.status-msg {
    font-size: 10px;
    position: absolute;
    top:-20px;
}