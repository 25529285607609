.gallery-container {
    margin: 75px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(240px,1fr));
    grid-auto-rows:240px;
}

.gallery-item {
    width: 100%;
    background: var(--img) center/cover;
}

.tall {
    grid-row: span 2 / auto;
}

.wide {
    grid-column: span 2 / auto;
}

.gallery-item:last-child {
    grid-column: span 3 / auto;
}

@media (max-width:1000px) {
    .gallery-container {
        margin:100px !important;
    }
}

@media (max-width:500px) {
    .gallery-container {
        margin:50px !important;
    }
}