@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face{
  font-family: 'BDSans';
  src: url('/public/assets/fonts/BDSans-Thin.ttf');
}

:root {
  --primary-color: #d9cb95;
  background-color: black;
  color: var(--primary-color);
  font-family: 'Montserrat';
}

html,body {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
}

.font-primary {
  font-family: 'BDSans';
}

.heading {
  font-size: 75px;
  margin:150px 0 0 60px;
}

@media (max-width:1000px) {
  .heading {
    text-align: center;
    margin-left: 0 !important;
  }
}