.home {
    display: flex;
}

@media (max-width:1000px) {
    .nav {
        margin:auto !important;
        transform: translateX(-25%);
    }
    .left-side {
        height: 450px;
    }
    .home {
        margin:0 !important;
        flex-direction: column;
        align-items: center;
    }
    .home .text {
        font-size: 100px !important;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .nav {
        transform: translate(-25%, 50px);
    }
    .left-side {
        height: 300px;
    }
    .home .text {
        margin:0 !important;
        font-size:60px !important;
        width: 500px !important;
    }
    .img {
        width: 250px !important;
        height:312px !important;
    }
}

.right-side {
    position: relative;
    width: 100%
}

.home .text {
    margin:50px;
    width: 500px;
    font-size: 7.77vw;
}

.img {
    margin:auto;
    --rotation: 20deg;
    rotate:var(--rotation);
    width: 400px;
    height: 500px;
    clip-path: ellipse(36% 50% at 60% 50%);
    position: relative;
    overflow: hidden !important;
}
  
.img:before {
    rotate: calc(0deg - var(--rotation));
    content: "";
    position: absolute;
    inset: -10%;
    background: url('/public/assets/images/IMG_5455.JPG') center/cover;
}

.home-contact {
    margin-top:20px;
    text-align: center;
    font-size:21px;
}

.socials {
    width:150px;
    gap:10px;
    position: absolute;
    left: 50%;
    margin-top: 10px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.social {
    height:30px;
}

.nav {
    margin-left:60px;
    font-size: 23px;
    position: relative;
    width: 200px;
}

.nav-item {
    width:150px;
    position: absolute;
}

a {
    color: var(--primary-color) !important;
    text-decoration: none;
}

.nav-item:nth-child(2), .nav-item:last-child {
    left: 200px;
}

.nav-item:nth-child(3), .nav-item:last-child {
    top:40px;
}

.nav-item:hover, .home-contact:hover {
    cursor: pointer;
}

.nav-item::after, .home-contact::after {
    content: '';
    width: 135px;
    height: 30px;
    top: 0;
    left: 0;
    background: var(--primary-color);
    position: absolute;
    transition: clip-path 0.5s;
    clip-path: polygon(50% 90%, 50% 90%, 50% 100%, 50% 100%);
}

.home-contact::after {
    top: calc(100% - 28px);
    left: 50%;
    transform: translateX(-50%);
}

.nav-item:hover::after, .home-contact:hover::after {
    clip-path: polygon(100% 90%, 0 90%, 0 100%, 100% 100%);
}

.social:hover {
    cursor: pointer;
    filter:brightness(120%);
}